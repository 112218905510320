import React, { useState, useEffect } from 'react'

import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'

// ICONS
import CallIcon from '@material-ui/icons/Call'
import AddIcon from '@material-ui/icons/Add'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import TimerIcon from '@material-ui/icons/Timer'
import MessageIcon from '@material-ui/icons/Message'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import LinearScaleIcon from '@material-ui/icons/LinearScale'

import { makeStyles } from '@material-ui/core/styles'
import { grey, blue } from '@material-ui/core/colors'
import { toast } from 'react-toastify'

import Chart from './Chart'
import ButtonWithSpinner from '../../components/ButtonWithSpinner'

import TableAttendantsStatus from '../../components/Dashboard/TableAttendantsStatus'
import { isArray } from 'lodash'

import useDashboard from '../../hooks/useDashboard'

import { isEmpty } from 'lodash'
import moment from 'moment'
import QueueSelect from 'components/QueueSelect'
import UserSelect from 'components/UserSelect'

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        height: 240,
        overflowY: 'auto',
        ...theme.scrollbarStyles,
    },
    cardAvatar: {
        fontSize: '55px',
        color: grey[500],
        backgroundColor: '#ffffff',
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardTitle: {
        fontSize: '18px',
        color: blue[700],
    },
    cardSubtitle: {
        color: grey[600],
        fontSize: '14px',
    },
    alignRight: {
        textAlign: 'right',
    },
    fullWidth: {
        width: '100%',
    },
    selectContainer: {
        width: '100%',
        textAlign: 'left',
    },
    card1: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.mode === 'light' ? '#284359' : '#284359',
        color: '#eee',
    },
    card2: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.mode === 'light' ? '#284359' : '#284359',
        color: '#eee',
    },
    card3: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.mode === 'light' ? '#284359' : '#284359',
        color: '#eee',
    },
    card4: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.mode === 'light' ? '#284359' : '#284359',
        color: '#eee',
    },
    card5: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.mode === 'light' ? '#284359' : '#284359',
        color: '#eee',
    },
    card6: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.mode === 'light' ? '#284359' : '#284359',
        color: '#eee',
    },
    card7: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.mode === 'light' ? '#284359' : '#284359',
        color: '#eee',
    },
    card8: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.mode === 'light' ? '#284359' : '#284359',
        color: '#eee',
    },
    card9: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.mode === 'light' ? '#284359' : '#284359',
        color: '#eee',
    },
}))

const DashItem = ({ xs = 12, sm = 6, md = 3, title, value, icon, ...props }) => {
    const classes = useStyles()

    return (
        <Grid item xs={xs} sm={sm} md={md}>
            <Paper
                className={classes.card3}
                style={{ overflow: 'hidden' }}
                elevation={6}>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <Typography component="h3" variant="h6" paragraph>
                            {title}
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {value}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        {icon}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

const Dashboard = () => {
    const classes = useStyles()
    const [counters, setCounters] = useState({})
    const [attendants, setAttendants] = useState([])
    const [planUse, setPlanUse] = useState([])
    const [filterType, setFilterType] = useState('date')
    const [period, setPeriod] = useState(0)
    const [dateFrom, setDateFrom] = useState(
        moment('1', 'D').format('YYYY-MM-DD'),
    )
    const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'))
    const [selectedUserIds, setSelectedUserIds] = useState([])
    const [selectedQueueIds, setSelectedQueueIds] = useState([])
    const [loading, setLoading] = useState(false)
    const { find } = useDashboard()
    useEffect(() => {
        async function firstLoad() {
            await fetchData()
        }
        setTimeout(() => {
            firstLoad()
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function handleChangePeriod(value) {
        setPeriod(value)
    }

    async function handleChangeFilterType(value) {
        setFilterType(value)
        if (value === 'date') {
            setPeriod(0)
        } else {
            setDateFrom('')
            setDateTo('')
        }
    }

    async function fetchData() {
        setLoading(true)

        let params = {
            users: selectedUserIds,
            queues: selectedQueueIds,
        }

        if (period > 0) {
            params = {
                days: period,
            }
        }

        if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
            params = {
                ...params,
                date_from: moment(dateFrom).format('YYYY-MM-DD'),
            }
        }

        if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
            params = {
                ...params,
                date_to: moment(dateTo).format('YYYY-MM-DD'),
            }
        }

        if (Object.keys(params).length === 0) {
            toast.error('Parametrize o filtro')
            setLoading(false)
            return
        }

        const data = await find(params)

        setCounters(data.counters)
        setPlanUse(data.planUse)
        if (isArray(data.attendants)) {
            setAttendants(data.attendants)
        } else {
            setAttendants([])
        }

        setLoading(false)
    }

    function formatTime(minutes) {
        return moment()
            .startOf('day')
            .add(minutes, 'minutes')
            .format('HH[h] mm[m]')
    }

    function renderFilters() {
        if (filterType === 'date') {
            return (
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Data Inicial"
                            type="date"
                            value={dateFrom}
                            onChange={e => setDateFrom(e.target.value)}
                            className={classes.fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Data Final"
                            type="date"
                            value={dateTo}
                            onChange={e => setDateTo(e.target.value)}
                            className={classes.fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </>
            )
        } else {
            return (
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="period-selector-label">
                            Período
                        </InputLabel>
                        <Select
                            labelId="period-selector-label"
                            id="period-selector"
                            value={period}
                            onChange={e => handleChangePeriod(e.target.value)}>
                            <MenuItem value={0}>Nenhum selecionado</MenuItem>
                            <MenuItem value={3}>Últimos 3 dias</MenuItem>
                            <MenuItem value={7}>Últimos 7 dias</MenuItem>
                            <MenuItem value={15}>Últimos 15 dias</MenuItem>
                            <MenuItem value={30}>Últimos 30 dias</MenuItem>
                            <MenuItem value={60}>Últimos 60 dias</MenuItem>
                            <MenuItem value={90}>Últimos 90 dias</MenuItem>
                        </Select>
                        <FormHelperText>
                            Selecione o período desejado
                        </FormHelperText>
                    </FormControl>
                </Grid>
            )
        }
    }

    return (
        <div>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3} justifyContent="flex-start">
                    {/* ATENDIMENTOS PENDENTES */}
                    <DashItem
                        title="Atd. Pendentes"
                        value={counters.supportPending}
                        icon={
                            <CallIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />

                    {/* ATENDIMENTOS ACONTECENDO */}
                    <DashItem
                        title="Atd. Acontecendo"
                        value={counters.supportHappening}
                        icon={
                            <HourglassEmptyIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />

                    {/* ATENDIMENTOS REALIZADOS */}
                    <DashItem
                        title="Finalizados"
                        value={counters.supportFinished}
                        icon={
                            <CheckCircleIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />

                    {/* TODOS ATENDIMENTOS */}
                    <DashItem
                        title="Todos Atd."
                        value={counters.supportAll}
                        icon={
                            <MessageIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />

                    {/* NOVOS CONTATOS */}
                    <DashItem
                        md={4}
                        title="Novos Contatos"
                        value={counters.leads}
                        icon={
                            <AddIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />

                    {/* T.M. DE ATENDIMENTO */}
                    <DashItem
                        md={4}
                        title="T.M. de Atendimento"
                        value={formatTime(counters.avgSupportTime)}
                        icon={
                            <AccessAlarmIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />

                    {/* T.M. DE ESPERA */}
                    <DashItem
                        md={4}
                        title="T.M. de Espera"
                        value={formatTime(counters.avgWaitTime)}
                        icon={
                            <TimerIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />

                    {/* FILTROS */}
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl className={classes.selectContainer}>
                            <InputLabel id="period-selector-label">
                                Tipo de Filtro
                            </InputLabel>
                            <Select
                                labelId="period-selector-label"
                                value={filterType}
                                onChange={e =>
                                    handleChangeFilterType(e.target.value)
                                }>
                                <MenuItem value="date">
                                    Filtro por Data
                                </MenuItem>
                                <MenuItem value="period">
                                    Filtro por Período
                                </MenuItem>
                            </Select>
                            <FormHelperText>
                                Selecione o período desejado
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    {renderFilters()}


                    <Grid item xs={12} sm={6} md={4}>
                        <QueueSelect
                            value={selectedQueueIds}
                            onChange={setSelectedQueueIds}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <UserSelect
                            value={selectedUserIds}
                            onChange={setSelectedUserIds}
                            multiple
                        />
                    </Grid>

                    {/* BOTAO FILTRAR */}
                    <Grid item xs={12} className={classes.alignRight}>
                        <ButtonWithSpinner
                            loading={loading}
                            onClick={() => fetchData()}
                            variant="contained"
                            color="primary">
                            Filtrar
                        </ButtonWithSpinner>
                    </Grid>

                    {/* DASHBOARD ATENDIMENTOS HOJE */}
                    <Grid item xs={12}>
                        <Paper className={classes.fixedHeightPaper}>
                            <Chart />
                        </Paper>
                    </Grid>

                    {/* USUARIOS ONLINE */}
                    <Grid item xs={12}>
                        {attendants.length ? (
                            <TableAttendantsStatus
                                attendants={attendants}
                                loading={loading}
                            />
                        ) : null}
                    </Grid>

                    <DashItem
                        title="Usuários"
                        value={planUse.users}
                        icon={
                            <PeopleAltOutlinedIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />
                    <DashItem
                        title="Setores"
                        value={planUse.queues}
                        icon={
                            <AccountTreeOutlinedIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />
                    <DashItem
                        title="Conexões"
                        value={planUse.connections}
                        icon={
                            <SyncAltIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />
                    <DashItem
                        title="Mensagens"
                        value={planUse.messages}
                        icon={
                            <ForumOutlinedIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />
                    <DashItem
                        title="Grupos"
                        value={planUse.groups}
                        icon={
                            <SupervisorAccountIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />
                    <DashItem
                        title="Tags"
                        value={planUse.tags}
                        icon={
                            <LocalOfferIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />
                    <DashItem
                        title="Bots"
                        value={planUse.chatFlows}
                        icon={
                            <LinearScaleIcon
                                style={{
                                    fontSize: 100,
                                    color: '#FFFFFF',
                                }}
                            />
                        }
                    />
                </Grid>
            </Container>
        </div>
    )
}

export default Dashboard
