const messages = {
    pt: {
        translations: {
            list: {
                searchPlaceholder: 'Pesquisar...',
            },

            login: {
                title: 'Login',
                form: {
                    email: 'Email',
                    password: 'Senha',
                },
                buttons: {
                    submit: 'Entrar',
                    register: 'Registre-se, agora mesmo!',
                },
            },
            plans: {
                form: {
                    name: 'Nome',
                    users: 'Usuários',
                    connections: 'Conexões',
                    enabled: 'Habilitadas',
                    disabled: 'Desabilitadas',
                    clear: 'Cancelar',
                    delete: 'Excluir',
                    save: 'Salvar',
                    yes: 'Sim',
                    no: 'Não',
                    money: 'R$',
                },
            },
            companies: {
                title: 'Cadastrar Empresa',
                form: {
                    name: 'Nome da Empresa',
                    plan: 'Plano',
                    token: 'Token',
                    submit: 'Cadastrar',
                    success: 'Empresa criada com sucesso!',
                },
            },
            auth: {
                toasts: {
                    success: 'Login efetuado com sucesso!',
                },
                token: 'Token',
            },
            dashboard: {
                charts: {
                    perDay: {
                        title: 'Atendimentos hoje: ',
                    },
                },
            },
            connections: {
                title: 'Conexões',
                toasts: {
                    deleted: 'Conexão com o WhatsApp excluída com sucesso!',
                },
                confirmationModal: {
                    deleteTitle: 'Deletar',
                    deleteMessage:
                        'Você tem certeza? Essa ação não pode ser revertida.',
                    disconnectTitle: 'Desconectar',
                    disconnectMessage:
                        'Tem certeza? Você precisará ler o QR Code novamente.',
                },
                buttons: {
                    add: 'Adicionar WhatsApp',
                    disconnect: 'desconectar',
                    tryAgain: 'Tentar novamente',
                    qrcode: 'QR CODE',
                    newQr: 'Novo QR CODE',
                    connecting: 'Conectando',
                },
                toolTips: {
                    disconnected: {
                        title: 'Falha ao iniciar sessão do WhatsApp',
                        content:
                            'Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code',
                    },
                    qrcode: {
                        title: 'Esperando leitura do QR Code',
                        content:
                            "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
                    },
                    connected: {
                        title: 'Conexão estabelecida!',
                    },
                    timeout: {
                        title: 'A conexão com o celular foi perdida',
                        content:
                            "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
                    },
                },
                table: {
                    name: 'Nome',
                    status: 'Status',
                    lastUpdate: 'Última atualização',
                    default: 'Padrão',
                    actions: 'Ações',
                    session: 'Sessão',
                },
            },
            whatsappModal: {
                title: {
                    add: 'Adicionar WhatsApp',
                    edit: 'Editar WhatsApp',
                },
                form: {
                    name: 'Nome',
                    default: 'Padrão',
                    queueRedirection: 'Redirecionamento de setor',
                    queueRedirectionDesc:
                        'Selecione um setor para os contatos que não possuem setor serem redirecionados',
                    expiresTicket: 'Encerrar chats abertos após x minutos',
                    expiresInactiveMessage:
                        'Mensagem de encerramento por inatividade',
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
                success: 'WhatsApp salvo com sucesso.',
            },
            qrCode: {
                message: 'Leia o QrCode para iniciar a sessão',
            },
            contacts: {
                title: 'Contatos',
                toasts: {
                    deleted: 'Contato excluído com sucesso!',
                },
                confirmationModal: {
                    deleteTitle: 'Deletar ',
                    importTitlte: 'Importar contatos',
                    deleteMessage:
                        'Tem certeza que deseja deletar este contato? Todos os atendimentos relacionados serão perdidos.',
                    importMessage:
                        'Deseja importar todos os contatos do telefone?',
                },
                buttons: {
                    import: 'Importar Contatos',
                    add: 'Adicionar Contato',
                },
                table: {
                    name: 'Nome',
                    whatsapp: 'WhatsApp',
                    email: 'Email',
                    actions: 'Ações',
                },
            },
            queueIntegrationModal: {
                title: {
                    add: 'Adicionar projeto',
                    edit: 'Editar projeto',
                },
                form: {
                    id: 'ID',
                    type: 'Tipo',
                    name: 'Nome',
                    projectName: 'Nome do Projeto',
                    language: 'Linguagem',
                    jsonContent: 'JsonContent',
                    urlN8N: 'URL',
                    typebotSlug: 'Typebot - Slug',
                    typebotExpires:
                        'Tempo em minutos para expirar uma conversa',
                    typebotKeywordFinish: 'Palavra para finalizar o ticket',
                    typebotKeywordRestart: 'Palavra para reiniciar o fluxo',
                    typebotRestartMessage: 'Mensagem ao reiniciar a conversa',
                    typebotUnknownMessage: 'Mensagem de opção inválida',
                    typebotDelayMessage: 'Intervalo (ms) entre mensagens',
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                    test: 'Testar Bot',
                },
                messages: {
                    testSuccess: 'Integração testada com sucesso!',
                    addSuccess: 'Integração adicionada com sucesso.',
                    editSuccess: 'Integração editada com sucesso.',
                },
            },
            contactModal: {
                title: {
                    add: 'Adicionar contato',
                    edit: 'Editar contato',
                },
                form: {
                    mainInfo: 'Dados do contato',
                    extraInfo: 'Informações adicionais',
                    name: 'Nome',
                    number: 'Número do Whatsapp',
                    email: 'Email',
                    extraName: 'Nome do campo',
                    extraValue: 'Valor',
                    whatsapp: 'Conexão Origem: ',
                    responsibleContainer: 'Cateira de clientes',
                    onlyResponsible: 'Acesso apenas para responsáveis',
                    queueResponsible: 'Setores Responsáveis',
                    userResponsible: 'Usuários Responsáveis',
                },
                buttons: {
                    addExtraInfo: 'Adicionar informação',
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
                success: 'Contato salvo com sucesso.',
            },
            queueModal: {
                title: {
                    add: 'Adicionar Setor',
                    edit: 'Editar Setor',
                },
                form: {
                    name: 'Nome',
                    color: 'Cor',
                    greetingMessage: 'Mensagem de saudação',
                    completionMessage: 'Mensagem de conclusão',
                    outOfHoursMessage: 'Mensagem de fora de expediente',
                    ratingMessage: 'Mensagem de avaliação',
                    token: 'Token',
                    orderQueue: 'Ordem do Setor (Bot)',
                    integrationId: 'Integração',
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
            },
            userModal: {
                title: {
                    add: 'Adicionar usuário',
                    edit: 'Editar usuário',
                },
                form: {
                    name: 'Nome',
                    email: 'Email',
                    password: 'Senha',
                    profile: 'Perfil',
                    whatsapp: 'Conexão Padrão',
                    inactive: 'Inativo',
                    profileType: {
                        admin: 'Administrador',
                        user: 'Usuário',
                    },
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
                success: 'Usuário salvo com sucesso.',
            },
            scheduleModal: {
                title: {
                    add: 'Novo Agendamento',
                    edit: 'Editar Agendamento',
                },
                form: {
                    body: 'Mensagem',
                    contact: 'Contato',
                    sendAt: 'Data de Agendamento',
                    sentAt: 'Data de Envio',
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
                success: 'Agendamento salvo com sucesso.',
            },
            tagModal: {
                title: {
                    add: 'Nova Tag',
                    edit: 'Editar Tag',
                },
                form: {
                    name: 'Nome',
                    color: 'Cor',
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
                success: 'Tag salvo com sucesso.',
            },
            chat: {
                noTicketMessage:
                    'Selecione um ticket para começar a conversar.',
            },
            uploads: {
                titles: {
                    titleUploadMsgDragDrop:
                        'ARRASTE E SOLTE ARQUIVOS NO CAMPO ABAIXO',
                    titleFileList: 'Lista de arquivo(s)',
                },
            },
            ticketsManager: {
                buttons: {
                    newTicket: 'Novo',
                },
            },
            ticketsQueueSelect: {
                placeholder: 'Setores',
                selectAll: {
                    all: 'Marcar tudo',
                    none: 'Desmarcar tudo',
                },
            },
            tickets: {
                toasts: {
                    deleted: 'O atendimento que você estava foi deletado.',
                },
                notification: {
                    message: 'Mensagem de',
                },
                tabs: {
                    open: { title: 'Abertas' },
                    closed: { title: 'Resolvidos' },
                    group: { title: 'Grupos' },
                },
                search: {
                    placeholder: 'Buscar atendimento e mensagens',
                },
                buttons: {
                    orderOlder: 'Antigos primeiro',
                },
            },
            transferTicketModal: {
                title: 'Transferir Ticket',
                fieldLabel: 'Digite para buscar usuários',
                fieldQueueLabel: 'Transferir para setor',
                fieldQueuePlaceholder: 'Selecione um setor',
                noOptions: 'Nenhum usuário encontrado com esse nome',
                buttons: {
                    ok: 'Transferir',
                    cancel: 'Cancelar',
                },
            },
            ticketsList: {
                pendingHeader: 'Aguardando',
                assignedHeader: 'Atendendo',
                botHeader: 'No Bot',
                noTicketsTitle: 'Nada aqui!',
                noTicketsMessage:
                    'Nenhum atendimento encontrado com esse status ou termo pesquisado',
                buttons: {
                    accept: 'Aceitar',
                    closed: 'Finalizar',
                    reopen: 'Reabrir',
                },
            },
            newTicketModal: {
                title: 'Criar Ticket',
                fieldLabel: 'Digite para pesquisar o contato',
                add: 'Adicionar',
                buttons: {
                    ok: 'Salvar',
                    cancel: 'Cancelar',
                },
            },
            mainDrawer: {
                listItems: {
                    dashboard: 'Dashboard',
                    connections: 'Conexões',
                    tickets: 'Atendimentos',
                    quickMessages: 'Respostas Rápidas',
                    contacts: 'Contatos',
                    queues: 'Setores',
                    tags: 'Tags',
                    administration: 'Administração',
                    users: 'Usuários',
                    settings: 'Configurações',
                    messagesAPI: 'API',
                    queueIntegration: 'Integrações',
                    chatFlow: 'ChatBot',
                    customField: 'Campos Dinâmicos',
                },
                appBar: {
                    user: {
                        profile: 'Perfil',
                        logout: 'Sair',
                    },
                },
            },
            queueIntegration: {
                title: 'Integrações',
                table: {
                    id: 'ID',
                    type: 'Tipo',
                    name: 'Nome',
                    projectName: 'Nome do Projeto',
                    language: 'Linguagem',
                    lastUpdate: 'Ultima atualização',
                    actions: 'Ações',
                },
                buttons: {
                    add: 'Adicionar Projeto',
                },
                confirmationModal: {
                    deleteTitle: 'Excluir',
                    deleteMessage:
                        'Você tem certeza? Essa ação não pode ser revertida! e será removida dos setores e conexões vinculadas',
                },
            },
            messagesAPI: {
                title: 'API',
                textMessage: {
                    number: 'Número',
                    body: 'Mensagem',
                    token: 'Token cadastrado',
                },
                mediaMessage: {
                    number: 'Número',
                    body: 'Nome do arquivo',
                    media: 'Arquivo',
                    token: 'Token cadastrado',
                },
            },
            notifications: {
                noTickets: 'Nenhuma notificação.',
            },
            quickMessages: {
                title: 'Respostas Rápidas',
                noAttachment: 'Sem anexo',
                confirmationModal: {
                    deleteTitle: 'Exclusão',
                    deleteMessage:
                        'Esta ação é irreversível! Deseja prosseguir?',
                },
                buttons: {
                    add: 'Adicionar',
                    attach: 'Anexar Arquivo',
                    cancel: 'Cancelar',
                    edit: 'Editar',
                },
                toasts: {
                    success: 'Atalho adicionado com sucesso!',
                    deleted: 'Atalho removido com sucesso!',
                },
                dialog: {
                    title: 'Mensagem Rápida',
                    shortcode: 'Atalho',
                    message: 'Resposta',
                    save: 'Salvar',
                    cancel: 'Cancelar',
                    geral: 'Permitir editar',
                    add: 'Adicionar',
                    edit: 'Editar',
                    visao: 'Permitir visão',
                },
                table: {
                    shortcode: 'Atalho',
                    message: 'Mensagem',
                    actions: 'Ações',
                    mediaPath: 'Nome do Arquivo',
                    status: 'Status',
                },
            },
            messageVariablesPicker: {
                label: 'Variáveis disponíveis',
                vars: {
                    contactFirstName: 'Primeiro Nome',
                    contactName: 'Nome',
                    greeting: 'Saudação',
                    protocolNumber: 'Protocolo',
                    date: 'Data',
                    hour: 'Hora',
                },
            },
            queues: {
                title: 'Setores',
                table: {
                    name: 'Nome',
                    color: 'Cor',
                    greeting: 'Mensagem de saudação',
                    actions: 'Ações',
                    orderQueue: 'Ordenação do Setor (bot)',
                },
                buttons: {
                    add: 'Adicionar Setor',
                },
                confirmationModal: {
                    deleteTitle: 'Excluir',
                    deleteMessage:
                        'Você tem certeza? Essa ação não pode ser revertida! Os atendimentos desse setor continuarão existindo, mas não terão mais nenhum setor atribuída.',
                },
            },
            queueSelect: {
                inputLabel: 'Setores',
            },
            users: {
                title: 'Usuários',
                table: {
                    name: 'Nome',
                    email: 'Email',
                    profile: 'Perfil',
                    actions: 'Ações',
                    profileType: {
                        admin: 'Administrador',
                        user: 'Usuário',
                    },
                },
                buttons: {
                    add: 'Adicionar usuário',
                },
                toasts: {
                    deleted: 'Usuário excluído com sucesso.',
                },
                confirmationModal: {
                    deleteTitle: 'Excluir',
                    deleteMessage:
                        'Todos os dados do usuário serão perdidos. Os atendimento abertos deste usuário serão movidos para o setor.',
                },
            },
            tags: {
                title: 'Tags',
                confirmationModal: {
                    deleteTitle: 'Você tem certeza que quer excluir esta Tag?',
                    deleteMessage: 'Esta ação não pode ser revertida.',
                },
                table: {
                    name: 'Nome',
                    color: 'Cor',
                    tickets: 'Ticket com essa Tag',
                    actions: 'Ações',
                },
                buttons: {
                    add: 'Nova Tag',
                },
                toasts: {
                    deleted: 'Tag excluído com sucesso.',
                },
            },
            settings: {
                success: 'Configurações salvas com sucesso.',
                title: 'Configurações',
                settings: {
                    userCreation: {
                        name: 'Criação de usuário',
                        options: {
                            enabled: 'Ativado',
                            disabled: 'Desativado',
                        },
                    },
                },
            },
            flow: {
                title: 'ChatBots',
                table: {
                    name: 'Nome',
                    actions: 'Ações',
                },
                buttons: {
                    add: 'Adicionar ChatBots',
                },
                toasts: {
                    deleted: 'ChatBot excluído com sucesso.',
                },
                confirmationModal: {
                    deleteTitle: 'Excluir',
                    deleteMessage: 'Todos os dados do ChatBot serão perdidos.',
                    createTitle: 'Criar ChatBot',
                },
                saveSuccess: 'ChatBot salvo com sucesso.',
            },
            customField: {
                title: 'Campos dinâmicos',
                form: {
                    edit: 'Editar',
                    add: 'Adicionar',
                },
                table: {
                    name: 'Nome',
                    actions: 'Ações',
                },
                buttons: {
                    add: 'Adicionar Campo',
                    cancel: 'Cancelar',
                    save: 'Salvar',
                },
                toasts: {
                    deleted: 'Campo Dinâmico excluído com sucesso.',
                    success: 'Campo Dinâmico salvo com sucesso.',
                },
                confirmationModal: {
                    deleteTitle: 'Excluir',
                    deleteMessage: 'Todos os Campos dinâmicos serão perdidos.',
                },
            },
            messagesList: {
                header: {
                    assignedTo: 'Atribuído à:',
                    buttons: {
                        return: 'Retornar',
                        resolve: 'Resolver',
                        reopen: 'Reabrir',
                        accept: 'Aceitar',
                    },
                },
            },
            messagesInput: {
                placeholderOpen: 'Digite uma mensagem',
                placeholderClosed:
                    'Reabra ou aceite esse ticket para enviar uma mensagem.',
                signMessage: 'Assinar',
            },
            contactDrawer: {
                header: 'Dados do contato',
                buttons: {
                    edit: 'Editar contato',
                },
                extraInfo: 'Outras informações',
            },
            fileModal: {
                title: {
                    add: 'Adicionar lista de arquivos',
                    edit: 'Editar lista de arquivos',
                },
                buttons: {
                    okAdd: 'Salvar',
                    okEdit: 'Editar',
                    cancel: 'Cancelar',
                    fileOptions: 'Adicionar arquivo',
                },
                form: {
                    name: 'Nome da lista de arquivos',
                    message: 'Detalhes da lista',
                    fileOptions: 'Lista de arquivos',
                    extraName: 'Mensagem para enviar com arquivo',
                    extraValue: 'Valor da opção',
                },
                success: 'Lista de arquivos salva com sucesso!',
            },
            ticketOptionsMenu: {
                schedule: 'Agendamento',
                delete: 'Deletar',
                transfer: 'Transferir',
                confirmationModal: {
                    title: 'Deletar o ticket do contato',
                    titleFrom: 'do contato ',
                    message:
                        'Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.',
                },
                buttons: {
                    delete: 'Excluir',
                    cancel: 'Cancelar',
                },
            },
            confirmationModal: {
                buttons: {
                    confirm: 'Ok',
                    cancel: 'Cancelar',
                },
            },
            messageOptionsMenu: {
                delete: 'Deletar',
                reply: 'Responder',
                react: 'Reagir',
                confirmationModal: {
                    title: 'Apagar mensagem?',
                    message: 'Esta ação não pode ser revertida.',
                },
            },
            backendErrors: {
                ERR_INACTIVE_USER: 'Seu usuário está inativo!',
                ERR_NO_OTHER_WHATSAPP:
                    'Deve haver pelo menos um WhatsApp padrão.',
                ERR_NO_DEF_WAPP_FOUND:
                    'Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.',
                ERR_WAPP_NOT_INITIALIZED:
                    'Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.',
                ERR_WAPP_CHECK_CONTACT:
                    'Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões',
                ERR_WAPP_INVALID_CONTACT:
                    'Este não é um número de Whatsapp válido.',
                ERR_WAPP_DOWNLOAD_MEDIA:
                    'Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.',
                ERR_INVALID_CREDENTIALS:
                    'Erro de autenticação. Por favor, tente novamente.',
                ERR_SENDING_WAPP_MSG:
                    'Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.',
                ERR_DELETE_WAPP_MSG:
                    'Não foi possível excluir a mensagem do WhatsApp.',
                ERR_OTHER_OPEN_TICKET:
                    'Já existe um tíquete aberto para este contato.',
                ERR_SESSION_EXPIRED: 'Sessão expirada. Por favor entre.',
                ERR_USER_CREATION_DISABLED:
                    'A criação do usuário foi desabilitada pelo administrador.',
                ERR_NO_PERMISSION:
                    'Você não tem permissão para acessar este recurso.',
                ERR_DUPLICATED_CONTACT: 'Já existe um contato com este número.',
                ERR_NO_SETTING_FOUND:
                    'Nenhuma configuração encontrada com este ID.',
                ERR_NO_CONTACT_FOUND: 'Nenhum contato encontrado com este ID.',
                ERR_NO_TICKET_FOUND: 'Nenhum tíquete encontrado com este ID.',
                ERR_NO_USER_FOUND: 'Nenhum usuário encontrado com este ID.',
                ERR_NO_WAPP_FOUND: 'Nenhum WhatsApp encontrado com este ID.',
                ERR_CREATING_MESSAGE:
                    'Erro ao criar mensagem no banco de dados.',
                ERR_CREATING_TICKET: 'Erro ao criar tíquete no banco de dados.',
                ERR_FETCH_WAPP_MSG:
                    'Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.',
                ERR_QUEUE_COLOR_ALREADY_EXISTS:
                    'Esta cor já está em uso, escolha outra.',
                ERR_WAPP_GREETING_REQUIRED:
                    'A mensagem de saudação é obrigatório quando há mais de um setor.',
                ERR_CHAT_FLOW_USE_QUEUE:
                    'Este setor está sendo usado em um ChatBot',
            },
        },
    },
}

export { messages }
